import React, { useState, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import emailjs from "@emailjs/browser";
// import apiKeys from "./apikeys";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./App.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  Input,
  Container,
  Card,
  CardImg,
  CardBody,
} from "reactstrap";
import { Link } from "react-scroll";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebook,
//   faTwitter,
//   faInstagram,
//   faYoutube,
//   faLinkedin
// } from "@fortawesome/free-brands-svg-icons";

import navLogo from "./assets/images/EverfinLogoTransparentBackground.png";
import mobileMockup from "./assets/images/EVERFINmockup_noBG_02.png";
import diegoHeadshot from "./assets/images/diego.jpg";
import janHeadshot from "./assets/images/jan.jpg";
// import rubiHeadshot from "./assets/images/rubi.jpg";
import thomasHeadshot from "./assets/images/thomas.jpg";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {/* <Route path="/login">
          <ComingSoon />
        </Route>
        <Route path="/signup">
          <ComingSoon />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path="/datenschutz">
          <Datenschutz />
        </Route>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/geschaftsbedingungen">
          <Geschaftsbedingungen />
        </Route> */}
      </Switch>
    </Router>
  );
}

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line
  const [signupFormIsActive, setSignupFormIsActive] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const notifySuccess = () =>
    toast.success("Email sent!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  const notifyError = () =>
    toast.error("Oops! Something went wrong...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  
  // const toggleSignupForm = event => {
  //   if(!signupFormIsActive){
  //     setSignupFormIsActive(true);
  //   }
  // }
    
  const sendEarlySignupEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_veov0ud',
        'early_signup',
        e.target,
        'qoF5AFILoqiusKZKb'
      )
      .then(
        result => {
          notifySuccess();
          console.log(result.text);
        },
        error => {
          notifyError();
          console.log(error.text);
        }
      );
  }

  const form = useRef();
  const sendContactFormEmail = (e) => {
    e.preventDefault();
    console.log(form.current)
    emailjs
      .sendForm('service_veov0ud', 'contact_form', form.current, 'qoF5AFILoqiusKZKb')
      .then(
        result => {
          notifySuccess();
          console.log(result.text);
        },
        error => {
          notifyError();
          console.log(error.text);
        }
      );
  }

  return (
    <div className="App" id="landing-page">
      <section id="hero-section">
        <Navbar light expand="md">
          <NavbarBrand href="/">
            <img className="" src={navLogo} alt="Everfin" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  to="meet-the-team-section"
                  spy={true}
                  smooth={true}
                  offset={-15}
                  duration={500}
                >
                  <NavLink href="/">Meet the team</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="contact-us-section"
                  spy={true}
                  smooth={true}
                  offset={-15}
                  duration={500}
                >
                  <NavLink href="/">Contact us</NavLink>
                </Link>
              </NavItem>
            </Nav>
            <Link
              to="curious-section"
              spy={true}
              smooth={true}
              offset={5}
              duration={500}
            >
              <NavLink id="signup-btn">
                Early sign up
              </NavLink>
            </Link>
          </Collapse>
        </Navbar>
        <Container id="hero-container">
          <Row>
            <Col className={signupFormIsActive ? 'display-none' : ''} id="hero-text-1">
              <img id="everfin-mockup-both" className="hide-on-desktop" src={mobileMockup} alt="Everfin" />
              <div>
                <h1 className="mb-0">Re-imagine Swiss<br/>occupational pension</h1>
                <p>for the better of employees<br className="hide-on-mobile" /> and employers.</p>
              </div>
              <Row>
                <Col>
                  <Link
                    to="curious-section"
                    spy={true}
                    smooth={true}
                    offset={5}
                    duration={500}
                  >
                    <Button>Early sign up</Button>
                  </Link>
                </Col>
                <Col>
                  <Link
                    to="contact-us-section"
                    spy={true}
                    smooth={true}
                    offset={-15}
                    duration={500}
                  >
                    <Button className="alt" href="/">Contact us</Button>
                  </Link>
                </Col>
              </Row>
            </Col>
            {/* <Col className={signupFormIsActive ? '' : 'display-none'} id="hero-text-2">
              <img id="everfin-mockup-both" className="hide-on-desktop" src={mobileMockup} alt="Everfin" />
              <p className="mb-5">The First tech platform<br/>that facilitate pension<br/>funds of the second<br/>pillar for employers and<br/>employees in Switzerland</p>
              <h1>Curious?</h1>
              <p>We are building a pension platform for modern companies to empower and benefit your people. Sign-up and we will keep you posted as we bring EVERFIN to market</p>
              <Form 
              // onSubmit={sendEmail}
              >
                <Row>
                  <Col className="full-width-col">
                    <FormGroup className="mb-2 mb-sm-0">
                      <Input type="email" name="from_email" placeholder="Enter your email" />
                    </FormGroup>
                  </Col>
                  <Col className="full-width-col">
                    <Button>Send</Button>
                  </Col>
                </Row>
                <ToastContainer />
              </Form>
            </Col> */}
            <Col className="hide-on-mobile">
              <img id="everfin-mockup-both" src={mobileMockup} alt="Everfin" />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="curious-section" className="mt-3">
        <Container>
          <Row>
            <Col>
              <h1>Curious?</h1>
              <p>We're building a pension platform for modern companies to empower and benefit your people. Sign-up and we will keep you posted as we bring EVERFIN to market.</p>
              <Form 
              onSubmit={sendEarlySignupEmail}
              >
                <Row>
                  <Col className="pr-0" xs={8} md={6}>
                    <FormGroup className="mb-2 mb-sm-0">
                      <Input required type="email" name="from_email" placeholder="Enter your email" />
                    </FormGroup>
                  </Col>
                  <Col xs={4} md={2}>
                    <Button className="full-width">Send</Button>
                  </Col>
                </Row>
                <ToastContainer />
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="meet-the-team-section">
        <Container>
          <Row className="mb-3">
            <Col>
              <h1>
                Meet the people
              </h1>
              <p>
                We're a dedicated team who thrives to<br/>boost your experience with the pension<br/>system in Switzerland
              </p>
            </Col>
          </Row>
          <Row className="hide-on-desktop">
            <Col className="pr-0">
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 35
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <Card>
                  <CardImg
                    alt="Card image cap"
                    src={janHeadshot}
                    top
                    width="100%"
                  />
                  <CardBody>
                    <p className="card-title">Founder (CEO)</p>
                    <p className="card-subtitle">Jan Hinrichs</p>
                    <p className="card-text">
                    Our Captain turns the company vision into reality taking great care of people, strategy, product, business development and marketing. Question about EVERFIN? He’ll guide you with passion and expertise!
                    </p>
                  </CardBody>
                </Card>
                <Card>
                <CardImg
                  alt="Card image cap"
                  src={thomasHeadshot}
                  top
                  width="100%"
                />
                  <CardBody>
                    <p className="card-title">Co-Founder (COO)</p>
                    <p className="card-subtitle">Thomas Gisler</p>
                    <p className="card-text">
                      Thomas is our Mr. Pension and knows the industry inside-out. Hence he takes care of our pension fund management & operations, plan & actuarial design, asset management and insurance solutions.
                    </p>
                  </CardBody>
                </Card>
                {/* <Card>
                <CardImg
                  alt="Card image cap"
                  src={rubiHeadshot}
                  top
                  width="100%"
                />
                  <CardBody>
                    <p className="card-title">UX Lead</p>
                    <p className="card-subtitle">Rubi Antognini</p>
                    <p className="card-text">
                    With meticulous attention to the user’s needs topped with a touch of UI, Rubi designed your experience for a frictionless immersion in the magical universe of the swiss second pillar.
                    </p>
                  </CardBody>
                </Card> */}
                <Card>
                <CardImg
                  alt="Card image cap"
                  src={diegoHeadshot}
                  top
                  width="100%"
                  // height="180px"
                />
                  <CardBody>
                    <p className="card-title">Tech Lead</p>
                    <p className="card-subtitle">Diego Spranger</p>
                    <p className="card-text">
                      Tech master and engineer guru, Diego built EVERFIN’s technology from the bottom to the top. From a solid backend to a smooth front-end, he ensured a seamless experience for you.
                    </p>
                  </CardBody>
                </Card>
              </Carousel>
            </Col>
          </Row>
          <Row id="team-cards-row" className="mb-4 hide-on-mobile">
            <Col>
              <Card>
                <CardImg
                  alt="Card image cap"
                  src={janHeadshot}
                  top
                  width="100%"
                />
                  <CardBody>
                    <p className="card-title">Founder (CEO)</p>
                    <p className="card-subtitle">Jan Hinrichs</p>
                    <p className="card-text">
                    Our Captain turns the company vision into reality taking great care of people, strategy, product, business development and marketing. Question about EVERFIN? He’ll guide you with passion and expertise!
                    </p>
                  </CardBody>
                </Card>
            </Col>
            <Col>
              <Card>
                <CardImg
                  alt="Card image cap"
                  src={thomasHeadshot}
                  top
                  width="100%"
                />
                  <CardBody>
                    <p className="card-title">Co-Founder (COO)</p>
                    <p className="card-subtitle">Thomas Gisler</p>
                    <p className="card-text">
                      Thomas is our Mr. Pension and knows the industry inside-out. Hence he takes care of our pension fund management & operations, plan & actuarial design, asset management and insurance solutions.
                    </p>
                  </CardBody>
                </Card>
            </Col>
            {/* <Col>
              <Card>
                <CardImg
                  alt="Card image cap"
                  src={rubiHeadshot}
                  top
                  width="100%"
                />
                  <CardBody>
                    <p className="card-title">UX Lead</p>
                    <p className="card-subtitle">Rubi Antognini</p>
                    <p className="card-text">
                    With meticulous attention to the user’s needs topped with a touch of UI, Rubi designed your experience for a frictionless immersion in the magical universe of the swiss second pillar.
                    </p>
                  </CardBody>
                </Card>
            </Col> */}
            <Col>
              <Card>
                <CardImg
                  alt="Card image cap"
                  src={diegoHeadshot}
                  top
                  width="100%"
                  // height="180px"
                />
                  <CardBody>
                    <p className="card-title">Tech Lead</p>
                    <p className="card-subtitle">Diego Spranger</p>
                    <p className="card-text">
                      Tech master and engineer guru, Diego built EVERFIN’s technology from the bottom to the top. From a solid backend to a smooth front-end, he ensured a seamless experience for you.
                    </p>
                  </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="contact-us-section" className="mt-3">
        <Container>
          <Row>
            <Col>
              <h1>Contact us!</h1>
              <p>Feel free to reach out if you have any questions<br/>or just want to connect with us.</p>
              <p>Or just give us a call at <a className="everfin-green-text" href="tel:+41796766554">+41 79 676 65 54</a></p>
            </Col>
          </Row>
          <Row>
            <Col>
              <form ref={form} onSubmit={sendContactFormEmail}>
                <input required type="email" name="from_email" placeholder="Enter your email" />
                <textarea required name="message" placeholder="Enter your message" rows="8"  />
                <input type="submit" value="Send" />
              </form>
              {/* <Form
                onSubmit={sendContactFormEmail}
                ref={form}
              >
                <Row>
                  <Col className="full-width-col">
                    <FormGroup className="mb-3">
                      <Input required type="email" name="from_email" placeholder="Enter your email" />
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Input required type="textarea" name="message" placeholder="Enter your message" rows="8" />
                    </FormGroup>
                    <Button className="float-right">Send</Button>
                  </Col>
                  <Col className="hide-on-mobile"></Col>
                </Row>
                <ToastContainer />
              </Form> */}
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section id="cta-section">
        <Container>
          <Row>
            <Col sm={1}>
            </Col>
            <Col sm={3}>
              <img
                id="fruit-basket"
                className=""
                src={fruitBasket}
                alt="fruit-basket"
              />
            </Col>
            <Col className="align-left">
              <h2 className="section-header">Abonnieren Sie den Newsletter</h2>
              <p className="dark-grey">
                Erhalten Sie sofort die neuesten Updates
              </p>
              <Form onSubmit={sendEmail}>
                <Row>
                  <Col className="full-width-col">
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <Input type="email" name="from_email" placeholder="Enter your email" />
                    </FormGroup>
                  </Col>
                  <Col className="full-width-col">
                    <Button className="dark-btn">Subscribe</Button>
                  </Col>
                </Row>
                <ToastContainer />
              </Form>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* <footer className="dark-grey">
        <Row>
          <Col>
            <Row>
              <Col className="footer-img">
                <img className="ml-3 mb-2" src={navLogo} alt="HelloFarmer" />
              </Col>
            </Row>
            <Row>
              <Col className="full-width-col">
                <p className="footer-text">Wir bringen regionale Bio-Produkte direkt per Klick zu Ihnen auf den Tisch</p>
              </Col>
              <Col className="full-width-col">
                <h5>Navigation</h5>
                <Link
                  to="hero-section"
                  spy={true}
                  smooth={true}
                  offset={150}
                  duration={500}
                >
                  <p>Leistungen</p>
                </Link>
                <Link
                  to="product-showcase-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Produkt</p>
                </Link>
                <Link
                  to="how-it-works-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Wie es funkioniert</p>
                </Link>
                <Link
                  to="farm-map-section"
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                >
                  <p>Hofkarte</p>
                </Link>
              </Col>
              <Col className="full-width-col">
                <h5>Other</h5> */}
                {/* <p><a href="/faq">FAQ</a></p> */}
                {/* <p><a href="/datenschutz">Datenschutz</a></p>
                <p><a href="/impressum">Impressum</a></p>
                <p><a href="/geschaftsbedingungen">Geschäftsbedingungen</a></p>
              </Col>
              <Col className="full-width-col">
                <h5>Contact</h5>
                <p>Hellofarmer UG</p>
                <p>Burggrafenstrasse 16</p>
                <p>10787 Berlin</p>
                <p>info@hellofarmer.de</p>
              </Col>
            </Row> */}
            {/* <Row>
              <Col className="footer-socials">
                <a href="/">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="/">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </Col>
            </Row> */}
            {/* Remove margin after adding more elements to footer */}
            {/* <Row className="mb-3">
              <Col>
                <p id="copyright">© 2021 HelloFarmer UG, all rights reserved</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer> */}
    </div>
  );
}

// function Faq() {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggle = () => setIsOpen(!isOpen);
   
//   return (
//     <div className="App">
//       <section id="hero-section">
//         <Navbar light expand="md">
//           <NavbarBrand href="/">
//             <img className="" src={navLogo} alt="Bautory" />
//           </NavbarBrand>
//           <NavbarToggler onClick={toggle} />
//           <Collapse isOpen={isOpen} navbar>
//             <Nav className="mr-auto" navbar>
//               <NavItem>
//                 <Link
//                   to="hero-section"
//                   spy={true}
//                   smooth={true}
//                   offset={150}
//                   duration={500}
//                 >
//                   <NavLink href="/services">Leistungen</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="product-showcase-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/product">Produkt</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="how-it-works-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/how-it-works">Wie es funktioniert</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="farm-map-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/farm-map">Hofkarte</NavLink>
//                 </Link>
//               </NavItem>
//             </Nav>
//             <NavLink href="https://app.hellofarmer.de/login">Anmeldung / Gast</NavLink>
//             <NavLink id="signup-btn" href="https://app.hellofarmer.de/register">
//               Registrieren
//             </NavLink>
//           </Collapse>
//         </Navbar>
//         <Container>
//           <Row>
//             <Col>
              
//             </Col>
//           </Row>
//         </Container>
//       </section>
//       <footer className="dark-grey">
//         <Row>
//           <Col>
//             <Row>
//               <Col className="footer-img">
//                 <img className="ml-3 mb-2" src={navLogo} alt="HelloFarmer" />
//               </Col>
//             </Row>
//             <Row>
//               <Col className="full-width-col">
//                 <p className="footer-text">Wir bringen regionale Bio-Produkte direkt per Klick zu Ihnen auf den Tisch</p>
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Navigation</h5>
//                 <Link
//                   to="hero-section"
//                   spy={true}
//                   smooth={true}
//                   offset={150}
//                   duration={500}
//                 >
//                   <p>Leistungen</p>
//                 </Link>
//                 <Link
//                   to="product-showcase-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <p>Produkt</p>
//                 </Link>
//                 <Link
//                   to="how-it-works-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <p>Wie es funkioniert</p>
//                 </Link>
//                 <Link
//                   to="farm-map-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <p>Hofkarte</p>
//                 </Link>
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Other</h5>
//                 {/* <p><a href="/faq">FAQ</a></p> */}
//                 <p><a href="/datenschutz">Datenschutz</a></p>
//                 <p><a href="/impressum">Impressum</a></p>
//                 <p><a href="/geschaftsbedingungen">Geschäftsbedingungen</a></p>
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Contact</h5>
//                 <p>Hellofarmer UG</p>
//                 <p>Burggrafenstrasse 16</p>
//                 <p>10787 Berlin</p>
//                 <p>info@hellofarmer.de</p>
//               </Col>
//             </Row>
//             {/* <Row>
//               <Col className="footer-socials">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </Col>
//             </Row> */}
//             {/* Remove margin after adding more elements to footer */}
//             <Row className="mb-3">
//               <Col>
//                 <p id="copyright">© 2021 HelloFarmer UG, all rights reserved</p>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </footer>
//     </div>
//   );
// }

// function Datenschutz() {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggle = () => setIsOpen(!isOpen);
   
//   return (
//     <div className="App">
//       <section id="hero-section">
//         <Navbar light expand="md">
//           <NavbarBrand href="/">
//             <img className="" src={navLogo} alt="Bautory" />
//           </NavbarBrand>
//           <NavbarToggler onClick={toggle} />
//           <Collapse isOpen={isOpen} navbar>
//             <Nav className="mr-auto" navbar>
//               <NavItem>
//                 <Link
//                   to="hero-section"
//                   spy={true}
//                   smooth={true}
//                   offset={150}
//                   duration={500}
//                 >
//                   <NavLink href="/services">Leistungen</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="product-showcase-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/product">Produkt</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="how-it-works-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/how-it-works">Wie es funktioniert</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="farm-map-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/farm-map">Hofkarte</NavLink>
//                 </Link>
//               </NavItem>
//             </Nav>
//             <NavLink href="https://app.hellofarmer.de/login">Anmeldung / Gast</NavLink>
//             <NavLink id="signup-btn" href="https://app.hellofarmer.de/register">
//               Registrieren
//             </NavLink>
//           </Collapse>
//         </Navbar>
//         <Container>
//           <Row>
//             <Col>
            
//             </Col>
//           </Row>
// 				</Container>
//       </section>
//       <footer className="dark-grey">
//         <Row>
//           <Col>
//             <Row>
//               <Col className="footer-img">
//                 <img className="ml-3 mb-2" src={navLogo} alt="HelloFarmer" />
//               </Col>
//             </Row>
//             <Row>
//               <Col className="full-width-col">
//                 <p className="footer-text">Wir bringen regionale Bio-Produkte direkt per Klick zu Ihnen auf den Tisch</p>
//               </Col>
//               <Col className="full-width-col hide-on-mobile">
                
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Other</h5>
//                 {/* <p><a href="/faq">FAQ</a></p> */}
//                 <p><a href="/datenschutz">Datenschutz</a></p>
//                 <p><a href="/impressum">Impressum</a></p>
//                 <p><a href="/geschaftsbedingungen">Geschäftsbedingungen</a></p>
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Contact</h5>
//                 <p>Hellofarmer UG</p>
//                 <p>Burggrafenstrasse 16</p>
//                 <p>10787 Berlin</p>
//                 <p>info@hellofarmer.de</p>
//               </Col>
//             </Row>
//             {/* <Row>
//               <Col className="footer-socials">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </Col>
//             </Row> */}
//             {/* Remove margin after adding more elements to footer */}
//             <Row className="mb-3">
//               <Col>
//                 <p id="copyright">© 2021 HelloFarmer UG, all rights reserved</p>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </footer>
//     </div>
//   );
// }

// function Impressum() {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggle = () => setIsOpen(!isOpen);
   
//   return (
//     <div className="App">
//       <section id="hero-section">
//         <Navbar light expand="md">
//           <NavbarBrand href="/">
//             <img className="" src={navLogo} alt="Bautory" />
//           </NavbarBrand>
//           <NavbarToggler onClick={toggle} />
//           <Collapse isOpen={isOpen} navbar>
//             <Nav className="mr-auto" navbar>
//               <NavItem>
//                 <Link
//                   to="hero-section"
//                   spy={true}
//                   smooth={true}
//                   offset={150}
//                   duration={500}
//                 >
//                   <NavLink href="/services">Leistungen</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="product-showcase-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/product">Produkt</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="how-it-works-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/how-it-works">Wie es funktioniert</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="farm-map-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/farm-map">Hofkarte</NavLink>
//                 </Link>
//               </NavItem>
//             </Nav>
//             <NavLink href="https://app.hellofarmer.de/login">Anmeldung / Gast</NavLink>
//             <NavLink id="signup-btn" href="https://app.hellofarmer.de/register">
//               Registrieren
//             </NavLink>
//           </Collapse>
//         </Navbar>
//         <Container>
//           <Row>
//             <Col>
//               <p>Angaben gemäß § 5 TMG:<br/>Hellofarmer UG<br/>10787 Berlin<br/>Deutschland</p>
//               <p>Vertreten durch:<br/>Matija Corkovic</p>
//               <p>Kontakt:<br/>E-Mail: info@hellofarmer.de</p>
//               <p>Handelsregister:<br/>Amtsgericht Charlottenburg (Berlin) HRB 231619 B</p>
//               <p>Umsatzsteuer-ID:<br/>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:<br/>DEXXXXXXXXX</p>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//       <footer className="dark-grey">
//         <Row>
//           <Col>
//             <Row>
//               <Col className="footer-img">
//                 <img className="ml-3 mb-2" src={navLogo} alt="HelloFarmer" />
//               </Col>
//             </Row>
//             <Row>
//               <Col className="full-width-col">
//                 <p className="footer-text">Wir bringen regionale Bio-Produkte direkt per Klick zu Ihnen auf den Tisch</p>
//               </Col>
//               <Col className="full-width-col hide-on-mobile">
                
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Other</h5>
//                 {/* <p><a href="/faq">FAQ</a></p> */}
//                 <p><a href="/datenschutz">Datenschutz</a></p>
//                 <p><a href="/impressum">Impressum</a></p>
//                 <p><a href="/geschaftsbedingungen">Geschäftsbedingungen</a></p>
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Contact</h5>
//                 <p>Hellofarmer UG</p>
//                 <p>Burggrafenstrasse 16</p>
//                 <p>10787 Berlin</p>
//                 <p>info@hellofarmer.de</p>
//               </Col>
//             </Row>
//             {/* <Row>
//               <Col className="footer-socials">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </Col>
//             </Row> */}
//             {/* Remove margin after adding more elements to footer */}
//             <Row className="mb-3">
//               <Col>
//                 <p id="copyright">© 2021 HelloFarmer UG, all rights reserved</p>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </footer>
//     </div>
//   );
// }

// function Geschaftsbedingungen() {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggle = () => setIsOpen(!isOpen);
   
//   return (
//     <div className="App">
//       <section id="hero-section">
//         <Navbar light expand="md">
//           <NavbarBrand href="/">
//             <img className="" src={navLogo} alt="Bautory" />
//           </NavbarBrand>
//           <NavbarToggler onClick={toggle} />
//           <Collapse isOpen={isOpen} navbar>
//             <Nav className="mr-auto" navbar>
//               <NavItem>
//                 <Link
//                   to="hero-section"
//                   spy={true}
//                   smooth={true}
//                   offset={150}
//                   duration={500}
//                 >
//                   <NavLink href="/services">Leistungen</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="product-showcase-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/product">Produkt</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="how-it-works-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/how-it-works">Wie es funktioniert</NavLink>
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   to="farm-map-section"
//                   spy={true}
//                   smooth={true}
//                   // offset={-70}
//                   duration={500}
//                 >
//                   <NavLink href="/farm-map">Hofkarte</NavLink>
//                 </Link>
//               </NavItem>
//             </Nav>
//             <NavLink href="https://app.hellofarmer.de/login">Anmeldung / Gast</NavLink>
//             <NavLink id="signup-btn" href="https://app.hellofarmer.de/register">
//               Registrieren
//             </NavLink>
//           </Collapse>
//         </Navbar>
//         <Container>
//           <Row>
//             <Col>
//             <p>Die folgenden Allgemeinen Geschäftsbedingungen („AGB“) der HelloFarmer UG mit Sitz in Berlin („Verkäufer“, „wir“ oder „uns“) regeln die Benutzung der HelloFarmer-App („App“) und das Vertragsverhältnis zwischen uns einerseits und Ihnen („Käufer“, „Nutzer“ oder „Sie“) andererseits.</p>
// 					<p>§ 1 Allgemeines</p>
// 					<p>§ 2 Vertragsschluss</p>
// 					<p>§ 3 Lieferung</p>
// 					<p>§ 4 Eigentumsvorbehalt</p>
// 					<p>§ 5 Zahlung, Fälligkeit, Zahlungsverzug</p>
// 					<p>§ 6 Preise / Liefer-/Versandkosten</p>
// 					<p>§ 7 Pfandartikel</p>
// 					<p>§ 8 Gewährleistung</p>
// 					<p>§ 9 Transportschäden</p>
// 					<p>§ 10 Widerrufsrecht</p>
// 					<p>§ 11 Ausschluss des Widerrufsrechts</p>
// 					<p>§ 12 Haftungsbeschränkung</p>
// 					<p>§ 13 Datenschutz</p>
// 					<p>§ 14 Anwendbares Recht und Gerichtsstand</p>
// 					<p>§ 15 Verbraucherstreitbeilegungsverfahren</p>
// 					<p>§ 16 Jugendschutz</p>
// 					<p>§ 17 Urheberrechte</p>
// 					<p>§ 18 Änderung der AGB</p>
// 					<p>§ 19 Schlussbestimmungen</p>
// 					<h5><b>§ 1 Allgemeines und Begriffsbestimmungen</b></h5>
// 					<p>(1) Alle Lieferungen und Leistungen, die von dem Verkäufer für den Käufer erbracht werden, erfolgen auf der Grundlage dieser AGB. Durch die Bestellung stimmt der Käufer diesen AGB ohne Einschränkungen zu. Stimmt der Käufer diesen AGB nicht zu, darf er keine Bestellung aufgeben.</p>
//             <p>Falls der Käufer Waren im Namen einer Gesellschaft, eines Unternehmens oder einer Organisation (zusammenfassend „Organisation“) bestellt, sichert er zu, dass er (a) ein
// Bevollmächtigter dieser Organisation ist und (b) befugt ist, diese Organisation an diese AGB zu binden. Diese AGB werden nur in deutscher Sprache angeboten.</p>
//             <p>(2) Zusätzlich gelten die Bestimmungen des Apple App Store („App Store“) und Google Play („Google Play“), die der Nutzer mit dem Anbieter des App Store und Google Play vereinbart.</p>
//             <p>(3) Sofern in diesen AGB besondere Rechte und Pflichten nur für Verbraucher gemäß § 13 BGB oder nur für Unternehmer gemäß § 14 BGB vereinbart werden, gelten folgende Begriffsbestimmungen:</p>
//             <p>(4) „Verbraucher“ im Sinne von § 13 BGB ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann.</p>
//             <p>(5) „Unternehmer“ im Sinne von § 14 BGB ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt. Eine rechtsfähige Personengesellschaft ist eine Personengesellschaft, die mit der Fähigkeit ausgestattet ist, Rechte zu erwerben und Verbindlichkeiten einzugehen.
//             </p>
//             <h5><b>§ 2 Vertragsschluss</b></h5>
//             <p>(1) Die Produktdarstellungen in der App sind keine verbindlichen Angebote des Verkäufers, sondern stellen lediglich eine Aufforderung des Verkäufers an den Käufer dar, selber ein Kaufangebot an den Verkäufer abzugeben. Insbesondere durch das Einlegen von Waren in den Warenkorb entsteht noch kein Kaufvertrag zwischen dem Käufer und dem Verkäufer. Die Produktdarstellungen müssen nicht immer mit dem Aussehen der gelieferten Produkte übereinstimmen. Mängelansprüche bestehen diesbezüglich insoweit nicht, als die Veränderungen für Sie zumutbar sind und nicht von einer Beschaffenheitsvereinbarung abweichen.</p>
//             <p>(2) Mit Absendung der Bestellung über die App durch Anklicken des Buttons „Jetzt kaufen“ gibt der Käufer ein verbindliches Angebot über die durch ihn in den Warenkorb gelegten Waren zu dem ausgewiesenen Preis ab. Der Mindestbestellwert beträgt 20,00 Euro.</p>
//             <p>(3) Nach Aufgabe der Bestellung kommt ein verbindlicher Vertrag mit uns zustande, wenn die Bestellung durch eine unverzüglich in der App angezeigte
// Bestellbestätigung angenommen wird und/oder der Käufer automatisch aufgefordert wird, die von ihm gewünschte Zahlungsart auszuwählen. Die Zahlung des Kaufpreises wird mit Vertragsschluss fällig. Sollte die Lieferung der bestellten Ware nicht möglich sein, etwa, weil die entsprechende Ware nicht auf Lager ist, wird dem Käufer keine Bestellbestätigung übermittelt bzw. erhält der Käufer keine Aufforderung zur Auswahl der Zahlungsart. t. In diesem Fall kommt ein Vertrag nicht zustande, worüber der Käufer unverzüglich informiert wird. Sollten Waren, die der Käufer gemäß Satz 1 verbindlich bestellt hat, ausnahmsweise doch nicht verfügbar sein, werden wir diesen hierüber unverzüglich informieren, nachdem wir Kenntnis von einem solchen Umstand erlangt haben. In einem solchen Fall werden wir den bereits entrichteten Kaufpreis für die betroffene Ware über die jeweilige Zahlungsart zurückerstatten oder ggf. mit dem Kunden individuell abstimmen, ob ein aus Sicht des Kunden gleichwertiges Ersatzprodukt gegen Aufrechterhaltung der Zahlungsverpflichtung geliefert werden kann.
// </p>
//             <p>(4) Waren können nur in handelsüblichen Mengen bestellt werden.</p>
//             <p>(5) Der Käufer kann die aktuell gültigen AGB des Verkäufers jederzeit
// unter https://www.hellofarmer.de/agb/einsehen, herunterladen oder speichern.</p>
//             <h5><b>§ 3 Lieferung</b></h5>
//             <p>(1) Sofern vor Vertragsschluss nichts Anderes vereinbart wird, erfolgt die Lieferung der Waren an die vom Käufer während des Bestellvorgangs ausgewählte Adresse und/oder Person in unserem Liefergebiet innerhalb Deutschlands. Lieferungen in andere Länder / Personen sind ausgeschlossen.</p>
//             <p>(2) Bitte beachten Sie, dass Waren, welche den Jugendschutzbestimmungen unterliegen (z.B. Tabakwaren, Alkoholika etc.) nur an volljährige Besteller (d.h. Personen ab dem vollendeten 18. Lebensjahr), welche sich bei der Lieferung durch einen Personalausweis auszuweisen haben, abgeliefert werden. Eine Ablieferung der bestellten Waren an Personen unter 18 Jahren findet nicht statt. Zudem erfolgt die Ablieferung der bestellten Waren nur an den Besteller persönlich. Zu diesem Zwecke sind wir berechtigt, die Vorlage eines Personalausweises oder eines vergleichbaren amtlichen Dokuments (Reisepass, Führerschein etc.) bei der Ablieferung zu verlangen. Sollte die Lieferung infolge einer unzureichenden Altersverifikation und/oder unzureichenden Personenidentifikation nicht übergeben werden können, sind die Mehrkosten für die Rücksendung der Bestellung vom Besteller zu tragen. Die Volljährigkeit ist darüber hinaus bereits während des Bestellvorgangs zu bestätigen.</p>
//             <p>(3) Das Datum der Lieferung der Ware an den Käufer durch den Verkäufer wird durch den Käufer bei der Bestellung angegeben.</p>
//             <p>(4) Lieferfristen für Sonderanfertigungen werden gesondert mit dem Käufer abgesprochen.</p>
//             <p>(5) Die Lieferung erfolgt nur gegen Zahlung der vereinbarten Versandkosten.</p>
//             <p>(6) Wir weisen darauf hin, dass der Verkäufer unabhängig von einer Transportversicherung das Risiko des Untergangs oder der Beschädigung der Ware auf dem Transportweg trägt, sofern der Käufer Verbraucher im Sinne des § 13 BGB (siehe § 1 Abs. 5 der AGB) ist.</p>
//             <p>(7) Ist der Käufer Unternehmer im Sinne von § 14 BGB (siehe § 1 Abs. 6 der AGB) geht die Gefahr des zufälligen Untergangs oder der Beschädigung der Ware auf dem Versandwege mit Abgabe der Ware an ein sorgfältig ausgesuchtes Transportunternehmen auf den Käufer über, sofern ein solches eingesetzt wird. Dies gilt auch, wenn die Leistung in mehreren Teilen erbracht wird.</p>
//             <p>(8) Bei Bestellung von mehreren Waren erfolgt die Lieferung einheitlich. Teillieferungen sind, soweit nichts Anderes vereinbart ist, grundsätzlich nicht möglich. Auf Wunsch des Käufers kann eine Teillieferung erfolgen. Die entstandenen Mehrkosten für den Versand trägt in diesem Fall der Käufer.</p>
//             <h5><b>§ 4 Eigentumsvorbehalt</b></h5>
//             <p>Die bestellte Ware bleibt bis zur vollständigen Bezahlung Eigentum des Verkäufers. Vor Eigentumsübertragung ist eine Verpfändung, Sicherungsübereignung, Verarbeitung oder Umgestaltung ohne ausdrückliche Einwilligung des Verkäufers nicht zulässig.</p>
//             <h5><b>§ 5 Zahlung, Fälligkeit, Zahlungsverzu</b></h5>
//             <p>(1) Der Kaufpreis für die bestellten Waren und für etwaige Liefer- und Versandkosten ist grundsätzlich sogleich nach Zustandekommen eines wirksamen Vertrages zu bezahlen.</p>
//             <p>(2) Ratenzahlungen bedürfen der vorherigen Abstimmung/Vereinbarung mit dem Verkäufer.</p>
//             <p>(3) Im Falle des Zahlungsverzuges ist der Verkäufer berechtigt, nach Ablauf einer angemessenen Frist zur Leistung, von dem Vertrag zurückzutreten.</p>
//             <h5><b>§ 6 Preise / Liefer- / Versandkosten</b></h5>
//             <p>(1) Es gilt ausschließlich der in der App angezeigte Kaufpreis der Ware. Alle Preise in der App verstehen sich inklusive der für das spezifische Produkt geltenden Mehrwertsteuer. Die Mehrwertsteuer wird bei jedem Produkt getrennt ausgewiesen. Der aktuelle Gesamtwert des Warenkorbes wird dem Kunden jederzeit im Warenkorb bzw. in der Bestellübersicht ausgewiesen.</p>
//             <p>(2) Die gesondert angegebenen Liefer- und Versandkosten gelten für die Lieferung / Versand innerhalb Deutschlands und werden dem Käufer vor Abgabe seiner Vertragserklärung deutlich mitgeteilt.</p>
//             <p>(3) Wir bieten Ihnen eine große Auswahl an Zahlungsmöglichkeiten, die in der App jeweils angegeben sind. Bezahlen Sie Ihren Online-Einkauf ganz bequem per Kreditkarte, PayPal oder Apple Pay. Selbstverständlich ist jede Zahlungsart für Sie kostenlos. Es entstehen keine separaten Gebühren.</p>
//             <h5><b>§ 7 Pfandartikel</b></h5>
//             <p>(1) Bei Getränken, auf deren Verpackung ein Pfand erhoben wird (Pfandgut), wird das Pfand automatisch dem ausgewiesenen Preis hinzugerechnet und die Höhe des Pfands separat ausgewiesen.</p>
//             <p>(2) Pfandgut kann in allen REWE-Märkten gegen Pfanderstattung abgegeben werden.</p>
//             <h5><b>§ 8 Gewährleistung</b></h5>
//             <p>(1) Ist der Käufer Verbraucher im Sinne des § 13 BGB (siehe § 1 Abs. 5 der AGB), so beträgt die Gewährleistungsfrist für neue Sachen 24 Monate ab der Ablieferung der
// Sache. Schadensersatzansprüche sind hiervon ausgenommen und richten sich nach § 12 dieser AGB.</p>
//             <p>(2) Ist der Käufer Unternehmer im Sinne des § 14 BGB (siehe § 1 Abs. 6 der AGB), so beträgt die Gewährleistungsfrist für neue Sachen ein Jahr ab Ablieferung der Sache. Für gebrauchte Sachen wird gegenüber einem Unternehmer keine Gewährleistung übernommen. Schadensersatzansprüche sind hiervon ausgenommen und richten sich nach § 12 dieser AGB.</p>
//             <p>(3) Schäden, die durch unsachgemäße oder vertragswidrige Maßnahmen des Käufers, bei Aufstellung, Anschluss, Bedienung oder Lagerung hervorgerufen werden, begründen keinen Anspruch gegen den Verkäufer. Die Unsachgemäßheit und Vertragswidrigkeit bestimmt sich insbesondere nach den Angaben des Herstellers der gelieferten Waren.</p>
//             <p>(4) Ist der Käufer Unternehmer im Sinne von § 14 BGB (siehe § 1 Abs. 6 der AGB), stehen ihm Gewährleistungsansprüche wegen bestehender Transportschäden nur zu, wenn er seiner Untersuchungs- und Anzeigepflicht gemäß § 9 dieser AGB nachgekommen ist.</p>
//             <p>(5) Im Übrigen richten sich die Ansprüche des Käufers gegen den Verkäufer bei Mängeln nach den gesetzlichen Regelungen.</p>
//             <h5><b>§ 9 Transportschäden</b></h5>
//             <p>Sofern der Käufer Unternehmer im Sinne von § 14 BGB (siehe § 1 Abs. 6 der AGB), ist, hat er die Ware sofort nach Empfangnahme auf Transportschäden zu untersuchen. Feststellbare Transportschäden sind unverzüglich schriftlich beim Verkäufer anzuzeigen. Verpackungsschäden muss sich der Käufer bei Annahme der Ware von dem Transportunternehmen schriftlich bestätigen lassen. Zeigt sich später ein solcher Mangel, so muss die Anzeige unverzüglich nach der Entdeckung erfolgen.</p>
//             <h5><b>§ 10 Widerrufsrecht</b></h5>
//             <p>Sofern Sie unsere Waren zu einem Zweck erwerben, der weder Ihrer gewerblichen noch Ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann, gelten für Sie als Verbraucher im Sinne des Gesetzes (§ 13 Bürgerliches Gesetzbuch) die folgenden Bestimmungen:</p>
//             <p>Widerrufsrecht</p>
//             <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
//             <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.</p>
//             <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
//             <p>Hellofarmer UG,</p>
//             <p>Burggrafenstraße 16,</p>
//             <p>10787 Berlin, Deutschland,</p>
//             <p>E-Mail: info@hellofarmer.de</p>
//             <p>mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.</p>
//             <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
//             <p>Folgen des Widerrufs</p>
//             <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart;</p>
//             <p>in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
//             <p>Wir holen die Waren ab. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.</p>
//             <p>Ende der Widerrufsbelehrung</p>
//             <p>Muster-Widerrufsformular</p>
//             <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
//             <p>- An Hellofarmer UG, Burggrafenstraße 16, 10787 Berlin, Deutschland, E-Mail: info@hellofarmer.de</p>
//             <p>- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)</p>
//             <p>- Bestellt am (*)/erhalten am (*)</p>
//             <p>- Name des/der Verbraucher(s)</p>
//             <p>- Anschrift des/der Verbraucher(s)</p>
//             <p>- Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
//             <p>- Datum</p>
//             <hr></hr>
//             <p>(*) Unzutreffendes streichen.</p>
//             <h5><b>§ 11 Ausschluss des Widerrufsrechts</b></h5>
//             <p>(1) Das Widerrufsrecht besteht laut Gesetz nicht bei Fernabsatzverträgen</p>
//             <p>- zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde,</p>
//             <p>- zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde</p>
//             <p>- zur Lieferung alkoholischer Getränke, deren Preis bei Vertragsschluss vereinbart wurde, die aber frühestens 30 Tage nach Vertragsschluss geliefert werden können und deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die der Unternehmer keinen Einfluss hat,</p>
//             <p>- in allen anderen in § 312g Abs. 2 BGB genannten Fällen.</p>
//             <p>(2) Das Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen auch dann, wenn der Unternehmer die Dienstleistung vollständig erbracht hat und mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der Verbraucher dazu seine ausdrückliche Zustimmung gegeben hat und gleichzeitig seine Kenntnis davon bestätigt hat, dass er sein Widerrufsrecht bei vollständiger Vertragserfüllung durch den Unternehmer verliert.</p>
//             <h5><b>§ 12 Haftungsbeschränkung</b></h5>
//             <p>(1) Der Verkäufer haftet unbeschränkt nach Maßgabe der gesetzlichen Bestimmungen, sofern der Käufer Ansprüche geltend macht, die auf Vorsatz oder grober Fahrlässigkeit beruhen sowie in Fällen der Übernahme einer Beschaffenheitsgarantie, beim arglistigen Verschweigen von Mängeln und bei einer Verletzung von Leben, Körper und
// Gesundheit. Bei leicht fahrlässig verursachten Sach- und Vermögensschäden durch den Verkäufer haftet der Verkäufer (ausgenommen die im vorstehenden Satz 1 genannten Fälle) nur im Falle der Verletzung einer wesentlichen Vertragspflicht, jedoch der Höhe nach begrenzt auf den bei Vertragsschluss vorhersehbaren und vertragstypischen Schaden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. Soweit der Verkäufer Leistungen unentgeltlich erbringt, haftet er nur nach Maßgabe des vorstehenden § 12 (1) Satz 1.</p>
//             <p>(2) Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.</p>
//             <p>(3) Eine über das Vorstehende hinausgehende Haftung des Verkäufers ist ausgeschlossen.</p>
//             <p>(4) Die vorstehenden Haftungsausschlüsse und -beschränkungen gelten auch im Hinblick auf die Haftung der Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen des Verkäufers.</p>
//             <h5><b>§ 13 Datenschutz</b></h5>
//             <p>Unsere Datenschutzbestimmungen finden Sie unter https://www.hellofarmer.de/privacy/.</p>
//             <h5><b>§ 14 Anwendbares Recht und Gerichtsstand</b></h5>
//             <p>(1) Alle Streitigkeiten aus diesem Rechtsverhältnis unterliegen dem Recht der Bundesrepublik Deutschland mit Ausnahme seiner kollisionsrechtlichen Bestimmungen. Die Geltung von UN-Kaufrecht ist ausgeschlossen. Sofern der Nutzer Verbraucher ist, steht ihm ungeachtet dessen nach Art. 6 Abs. 2 der Verordnung (EG) 593/2008 auch der Schutz der zwingenden Bestimmungen desjenigen Rechts zu, das ohne diese Klausel anzuwenden wäre.</p>
//             <p>(2) Für Streitigkeiten aus oder im Zusammenhang mit der Lieferung der Waren und/oder diesen AGB ist ausschließlicher Gerichtsstand Berlin, sofern der Käufer Kaufmann ist oder es sich beim Käufer um eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt oder der Käufer keinen festen Wohnsitz in Deutschland hat, der Käufer den Wohnsitz oder seinen gewöhnlichen Aufenthaltsort nach Wirksamwerden dieser AGB ins Ausland verlegt hat oder wenn der Wohnsitz oder gewöhnlicher Aufenthaltsort des Käufers im Zeitpunkt der Klageerhebung nicht bekannt ist.</p>
//             <h5><b>§ 15 Verbraucherstreitbeilegungsverfahren</b></h5>
//             <p>(1) Wir weisen darauf hin, dass wir zu einer Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nicht verpflichtet und nicht bereit sind. Vielmehr sind wir stets darum bemüht, etwaige Konflikte mit unseren Kunden selbst zu lösen.</p>
//             <p>(2) Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten („OS-Plattform“) zwischen Unternehmern und Verbrauchern eingerichtet. Die OS-Plattform ist erreichbar unter www.ec.europa.eu/consumers/odr/.</p>
//             <h5><b>§ 16 Jugendschutz</b></h5>
//             <p>(1) Die Übergabe von Waren, die gesetzlichen Verkaufsbeschränkungen unterliegen, erfolgt nur an Personen, die die gesetzlichen Voraussetzungen erfüllen. Die Mitarbeiter der Hellofarmer UG dürfen zu diesem Zweck die Vorlage eines amtlichen Lichtbildausweises verlangen.</p>
//             <p>(2) Es besteht die Möglichkeit, alkoholische Waren und Tabakwaren zu bestellen. Hellofarmer weist darauf hin, dass alkoholische Waren und Tabakwaren nur an Personen über 18 Jahre ausgegeben werden (vgl. hierzu auch § 3 Abs. 2).</p>
//             <h5><b>§ 17 Urheberrechte</b></h5>
//             <p>Die HelloFarmer-App ist urheberrechtlich geschützt. Wir räumen Ihnen das nicht ausschließliche, nicht unterlizenzierbare und nicht übertragbare, zeitlich beschränkte und jederzeit widerrufliche Recht ein, die App zu nutzen, um Waren gemäß diesen AGB über die App zu bestellen. Eine weitergehende Verwendung der App, einschließlich der dargestellten Bilder, Zeichen, Symbole oder Produktbeschreibungen ist ohne unsere ausdrückliche Zustimmung nicht gestattet. Bei der Nutzung der App ist es Ihnen insbesondere untersagt, die auf der App dargestellten Informationen, insbesondere zu der auf der App angebotenen Ware, massenhaft zu extrahieren (z.B. durch sog. „Scraping“) und/oder die Informationen außerhalb der App weiterzuverwenden.</p>
//             <h5><b>§ 18 Änderung der AGB</b></h5>
//             <p>(1) Wir behalten uns vor, unsere AGB jederzeit zu ändern, außer dies ist für den Nutzer nicht zumutbar. Für neue Vertragsabschlüsse gelten dann jeweils die AGB in der zum Vertragsabschluss geltenden Fassung.</p>
//             <p>(2) Bei einem laufenden Dauerschuldverhältnis werden wir Ihnen eine Änderung der AGB mindestens 4 Wochen mittels einer Push-Benachrichtigung innerhalb der App oder per E- Mail mitteilen. Widersprechen Sie innerhalb dieser 4-Wochen-Frist nicht oder öffnen die App erneut, gelten die geänderten AGB mit Wirkung für die Zukunft als angenommen und werden zum Zeitpunkt des Wirksamwerdens Vertragsbestandteil. Wir werden Sie in der Mitteilung, die die geänderten Bedingungen enthält, auf die Wirkung des Schweigens und die Bedeutung der 4-Wochenfrist hinweisen.</p>
//             <p>Eine Änderung der AGB kommt insbesondere in Betracht, soweit</p>
//             <p>1. die Änderungen oder Anpassungen lediglich vorteilhaft für die Nutzer sind,</p>
//             <p>2. dieÄnderungenoderAnpassungenerforderlichsind,umeine
// Übereinstimmung mit geltendem Recht herzustellen, vor allem auch, wenn sich die geltende Rechtslage ändert, oder um einem Gerichtsurteil oder einer Behördenentscheidung nachzukommen,</p>
//             <p>3. wenn die Änderungen oder Anpassungen ohne wesentlichen Einfluss auf Funktionen der Dienste oder rein technischer oder organisatorischer Art sind, oder</p>
//             <p>4. dieszusätzliche,gänzlichneueDiensteoderLeistungenbetrifft,dieeiner Beschreibung in den AGB bedürfen, es sei denn, dass dies für das laufende Nutzungsverhältnis nachteilig wäre.</p>
//             <p>(3) Der vorstehende Absatz (2) gilt nicht für solche Änderungen, die zu einer Änderung des Äquivalenzverhältnisses von Leistung und Gegenleistung oder zu einer Umgestaltung des Vertragsgefüges insgesamt führen würden. In diesem Fall werden wir Ihnen anbieten, den Vertrag zu den ursprünglichen Bedingungen fortzusetzen.</p>
//             <h5><b>§ 19 Schlussbestimmungen</b></h5>
//             <p>(1) Vertragssprache ist deutsch.</p>
//             <p>(2) Die Unwirksamkeit einer oder mehrerer Bestimmungen dieses Vertrages lässt die Wirksamkeit des Vertrages im Übrigen unberührt. Anstelle der unwirksamen Bestimmungen treten die geltenden gesetzlichen Vorschriften.</p>
//             <p>Stand: September, 2021</p>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//       <footer className="dark-grey">
//         <Row>
//           <Col>
//             <Row>
//               <Col className="footer-img">
//                 <img className="ml-3 mb-2" src={navLogo} alt="HelloFarmer" />
//               </Col>
//             </Row>
//             <Row>
//               <Col className="full-width-col">
//                 <p className="footer-text">Wir bringen regionale Bio-Produkte direkt per Klick zu Ihnen auf den Tisch</p>
//               </Col>
//               <Col className="full-width-col hide-on-mobile">
                
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Other</h5>
//                 {/* <p><a href="/faq">FAQ</a></p> */}
//                 <p><a href="/datenschutz">Datenschutz</a></p>
//                 <p><a href="/impressum">Impressum</a></p>
//                 <p><a href="/geschaftsbedingungen">Geschäftsbedingungen</a></p>
//               </Col>
//               <Col className="full-width-col">
//                 <h5>Contact</h5>
//                 <p>Hellofarmer UG</p>
//                 <p>Burggrafenstrasse 16</p>
//                 <p>10787 Berlin</p>
//                 <p>info@hellofarmer.de</p>
//               </Col>
//             </Row>
//             {/* <Row>
//               <Col className="footer-socials">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </Col>
//             </Row> */}
//             {/* Remove margin after adding more elements to footer */}
//             <Row className="mb-3">
//               <Col>
//                 <p id="copyright">© 2021 HelloFarmer UG, all rights reserved</p>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </footer>
//     </div>
//   );
// }

// function ComingSoon() {
//   // const [isOpen, setIsOpen] = useState(false);
//   // const toggle = () => setIsOpen(!isOpen);
//   const notifySuccess = () =>
//     toast.success("Email sent!", {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined
//     });
//   const notifyError = () =>
//     toast.error("Oops! Something went wrong...", {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined
//     });
//   function sendEmail(e) {
//     e.preventDefault();

//     emailjs
//       .sendForm(
//         "service_rrsuuou",
//         "template_ny7irfz",
//         e.target,
//         "user_XDrOquYr4X0wis2x2wiYq"
//       )
//       .then(
//         result => {
//           notifySuccess();
//           console.log(result.text);
//         },
//         error => {
//           notifyError();
//           console.log(error.text);
//         }
//       );
//   }
//   return (
//     <div className="App">
//       <section id="hero-section">
//         <Navbar light expand="md">
//           <NavbarBrand href="/">
//             <img className="" src={navLogo} alt="Bautory" />
//           </NavbarBrand>
//         </Navbar>
//         <Container className="coming-soon-section">
//           <Row>
//             <Col className="flex-col">
//               <h1 className="mb-5">Bald Verfügbar</h1>
//               <p className="greyed-out">
//                 Wir arbeiten zur Zeit an der Fertigstellung von Hellofarmer,
//                 bleibt dran für weitere Neuigkeiten
//               </p>
//               <Form onSubmit={sendEmail} inline>
//                 <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
//                   {/* <Label for="exampleName" className="mr-sm-2">
//               Name
//             </Label> */}
//                   <Input type="text" name="from_name" placeholder="Your name" />
//                 </FormGroup>
//                 <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
//                   {/* <Label for="exampleEmail" className="mr-sm-2">
//               Email
//             </Label> */}
//                   <Input
//                     type="email"
//                     name="from_email"
//                     placeholder="Your email"
//                   />
//                 </FormGroup>
//                 <Button className="dark-btn">BENACHRICHTIGEN</Button>
//                 <ToastContainer />
//               </Form>
//               <div className="my-5">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//       <footer>
//         <Row>
//           <Col>
//             <Row>
//               <Col>
//                 <img className="ml-3 mb-2" src={navLogo} alt="Bautory" />
//               </Col>
//             </Row>
//             <Row>
//               <Col className="footer-socials">
//                 <a href="/">
//                   <FontAwesomeIcon icon={faFacebook} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faTwitter} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faLinkedin} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faInstagram} />
//                 </a>
//                 <a href="/">
//                   <FontAwesomeIcon icon={faYoutube} />
//                 </a>
//               </Col>
//             </Row>
//             {/* Remove margin after adding more elements to footer */}
//             <Row className="mt-5">
//               <Col>
//                 <p>© 2021 HelloFarmer, all rights reserved</p>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </footer>
//     </div>
//   );
// }

export default App;
